import {Alert, Button} from 'react-bootstrap';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {TPreIntake} from '../../../types/intake';
import {useTranslation} from 'react-i18next';

//interfaces
interface RegisterCaseSuccessProps {
    preIntake: TPreIntake
    onReset: () => void
}

/**
 * A React component that displays a success message after a case registration.
 * Provides functionality to display the case details and an option to register another case.
 *
 * Props:
 * @param {TPreIntake} preIntake - The object containing pre-intake details, including the case ID.
 * @param {() => void} onReset - A callback function to reset the component state for registering another case.
 */
const RegisterCaseSuccess = ({preIntake, onReset}: RegisterCaseSuccessProps) => {
    // Accesses translation function for multilingual support.
    // eslint-disable-next-line
    const {t}: { t: any } = useTranslation();

    /**
     * Renders a success alert component.
     * The alert includes an SVG icon, a success message, and the pre-case ID.
     *
     * @returns {JSX.Element} The success alert component.
     */
    const renderAlert = () => {
        return <Alert variant="subtle-success" className="border-0">
            <div className="d-flex align-items-center gap-3">
                <div className="me-2">
                    {/* SVG Icon for the success alert */}
                    <svg
                        width="40"
                        height="41"
                        viewBox="0 0 40 41"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M15.4763 3.3191C14.1966 4.59877 12.9285 5.19288 11.1042 5.19288C9.51121 5.19288 7.24485 4.88393 6 6.13925C4.76499 7.38465 5.07256 9.64167 5.07256 11.2245C5.07256 13.0489 4.4784 14.317 3.19871 15.5967C1.06625 17.7292 1.84304e-05 18.7954 0 20.1203C3.27935e-05 21.4453 1.06627 22.5116 3.19876 24.644C4.63231 26.0775 5.07256 27.0032 5.07256 29.0162C5.07256 30.6092 4.76362 32.8755 6.01898 34.1204C7.26437 35.3553 9.52136 35.0478 11.1041 35.0478C13.047 35.0478 13.9826 35.4278 15.3691 36.8144C16.5498 37.9951 18.1326 40.1203 20 40.1204C21.8675 40.1203 23.4502 37.9951 24.6309 36.8144C26.0174 35.4278 26.953 35.0478 28.8959 35.0478C30.4786 35.0478 32.7356 35.3553 33.981 34.1204C35.2364 32.8755 34.9274 30.6092 34.9274 29.0162C34.9274 27.0032 35.3677 26.0775 36.8012 24.644C38.9337 22.5116 40 21.4453 40 20.1203C40 18.7954 38.9338 17.7292 36.8013 15.5967C35.3677 14.1631 34.9274 13.2375 34.9274 11.2245C34.9274 9.63154 35.2364 7.36522 33.9811 6.12036C32.7357 4.8853 30.4787 5.19288 28.8958 5.19288C27.0709 5.19288 25.8031 4.5985 24.5237 3.3191C22.3912 1.18661 21.325 0.120362 20 0.120361C18.675 0.120362 17.6088 1.18661 15.4763 3.3191Z"
                            fill="#71932E"
                        />
                        <path
                            d="M14 21.9061L17.6 25.1204L26 15.1204"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
                <div>
                  <span className="fs-6 fw-semibold">
                    {t("register_successfully")}
                  </span>
                    <p className="mb-0">
                        {t("your_pre_case_id_is")}&nbsp;
                        <span className="fw-semibold">{preIntake?.pre_intake_case_id}</span>.
                    </p>
                </div>
            </div>
        </Alert>
    }
    return (
    <>
        {/* Renders the success alert */}
        {renderAlert()}

        {/* Warning text to save the case ID */}
        <p className="text-danger">
            {t("save_this_id_for_future_purpose",{id:preIntake?.pre_intake_case_id})}
        </p>

        {/* Additional success message */}
        <p>
            {t("case_registered_successfully")}
        </p>

        {/* Button to register another case */}
        <Button variant="success rounded-pill w-100 mb-4" onClick={onReset}>
            {t("register_another_case")}
            <FontAwesomeIcon icon={faChevronRight} className="ms-2"/>
        </Button>
    </>);
}
export default RegisterCaseSuccess
