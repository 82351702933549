import { useDispatch, useSelector } from '../../../store';
import {
  TCase,
  TCaseCount,
  TFilterCase,
  TTrackCase
} from '../../../types/cases';
import CaseService from '../../../services/case/CaseService';
import {
  getAllCase,
  getOneCase,
  getCaseCount
} from '../../../store/reducers/case/caseSlice';

const useCaseHook = () => {
  const dispatch = useDispatch();
  const { cases, caseDetail, meta, count } = useSelector(state => state.case);

  const fetchAllCase = async (filters: TFilterCase): Promise<TCase[]> => {
    try {
      const resp = await CaseService.fetchAll(filters);
      const meta = resp?.data?.data?.meta || {};
      const results: TCase[] = resp?.data?.data?.rows || [];
      dispatch(getAllCase({ results, meta }));
      return results;
    } catch (e) {
      dispatch(getAllCase({ results: [], meta: {} }));
      console.log('fetchAllCase', e);
      throw e;
    }
  };

  const fetchOneCase = async (id: string): Promise<TCase> => {
    try {
      const resp = await CaseService.fetchOne(id);
      const row = resp?.data?.data || null;

      dispatch(getOneCase({ row }));
      return row;
    } catch (e) {
      dispatch(getOneCase({ row: null }));
      console.log('fetchOneCase', e);
      throw e;
    }
  };

  const trackOneCase = async (data: TTrackCase): Promise<TTrackCase> => {
    try {
      const resp = await CaseService.trackCase(data);
      return resp?.data?.data || null;
    } catch (e) {
      console.log('fetchOneCase', e);
      throw e;
    }
  };

  const fetchCaseCount = async (): Promise<TCaseCount> => {
    try {
      const resp = await CaseService.fetchCount();
      const row = resp?.data?.data || null;
      dispatch(getCaseCount({ row }));
      return row;
    } catch (e) {
      console.log('fetchCaseCount', e);
      throw e;
    }
  };

  return {
    fetchAllCase,
    fetchOneCase,
    trackOneCase,
    fetchCaseCount,
    cases,
    meta,
    count,
    caseDetail
  };
};

export default useCaseHook;
