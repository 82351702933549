import { Dropdown, Nav } from 'react-bootstrap';
import { UilBell } from '@iconscout/react-unicons';
import Unicon from 'components/base/Unicon';
import Avatar from 'components/base/Avatar';
import ProfileDropdownMenu from './ProfileDropdownMenu';
import { Link, useNavigate } from 'react-router-dom';
import avatar57 from 'assets/img/team/40x40/57.webp';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { ChangeEvent, useState } from 'react';
import { checkScope } from '../../../helpers/auth';
import { useTranslation } from 'react-i18next';

import { changeLanguage } from '../../../i18n';

const NavItems = () => {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  // eslint-disable-next-line
  const handleChangeLanguage = (event: ChangeEvent<HTMLSelectElement>) => {
    const language = event.target.value;
    changeLanguage(language);
  };

  return (
    <div className="navbar-nav navbar-nav-icons flex-row align-items-center">
      <div className="me-4">
        {checkScope('pre-intake-create') && (
          <Button
            onClick={() => {
              navigate('/pre-intake/add');
            }}
            variant="subtle-primary"
            className="w-100 rounded-pill"
          >
            <FontAwesomeIcon icon={faAdd} className="me-2" />
            {t('add_new_case')}
          </Button>
        )}
      </div>
      <Nav.Item>
        <a href="#" className="dropdown-caret-none nav-link h-100">
          <Unicon icon={UilBell} className="text-black" />
        </a>
      </Nav.Item>
      <Nav.Item>
        <select onChange={handleChangeLanguage} defaultValue={i18n.language}>
          <option value="en">EN</option>
          <option value="km">KM</option>
        </select>
      </Nav.Item>
      <Nav.Item>
        <Dropdown
          autoClose="outside"
          className="h-100"
          show={showDropdown}
          onToggle={isOpen => setShowDropdown(isOpen)}
        >
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link pe-0 py-0 lh-1 h-100 d-flex align-items-center"
            variant=""
          >
            <Avatar src={avatar57} size="l" />
          </Dropdown.Toggle>
          <ProfileDropdownMenu handleToggle={() => setShowDropdown(false)} />
        </Dropdown>
      </Nav.Item>
    </div>
  );
};

export default NavItems;
