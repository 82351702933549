import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Stack } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Avatar from 'components/base/Avatar';
import generic66 from 'assets/img/generic/66.jpg';
import { ListViewIcon } from 'hugeicons-react';
import PreIntakeForm from '../../../components/modules/pre-intake/forms/PreIntakeForm';
import usePreIntakeHook from '../../../hooks/modules/pre-intake/usePreIntakeHook';
import { TPreIntake } from '../../../types/intake';
import { toast } from 'react-toastify';
import { checkScope } from '../../../helpers/auth';
import { useTranslation } from 'react-i18next';
import useAuthHook from '../../../hooks/modules/useAuthHook';

/**
 * PreIntakeAdd component that handles the logic for adding and editing PreIntake records.
 * @returns {JSX.Element}
 * @constructor
 */
const PreIntakeAdd = () => {
  // Translation hook for handling multi-language support
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();

  // Fetching the 'id' parameter from the route's URL, used for editing an existing PreIntake
  const { id } = useParams();

  // Auth hook to get user details and ensure only authorized users can access this page
  const { user } = useAuthHook();

  // Navigation hook to programmatically redirect the user
  const navigate = useNavigate();

  // Check if the user has the required scope to create PreIntakes, if not, redirect to a 403 error page
  useEffect(() => {
    if (!checkScope('pre-intake-create')) navigate('/error/403');
  }, []);

  // Custom hook for handling PreIntake CRUD operations
  const { createPreIntake, updatePreIntake, fetchOnePreIntake } =
    usePreIntakeHook();

  // State management
  const [loading, setLoading] = useState(false);
  const [preIntake, setPreIntake] = useState<TPreIntake>();

  // Fetch PreIntake data when 'id' is available (i.e., when editing an existing PreIntake)
  useEffect(() => {
    if (id) {
      setLoading(true);
      fetchOnePreIntake(parseInt(id))
        .then(data => setPreIntake(data))
        .catch(() => navigate('/pre-intake'))
        .finally(() => setLoading(false));
    } else {
      setPreIntake(undefined);
    }
  }, [id]);

  // Handler function for submitting the PreIntake form (for both creating and updating)
  const handleOnSubmit = (formData: TPreIntake) => {
    setLoading(true);
    if (preIntake) {
      // If there's an existing PreIntake (editing), update it
      updatePreIntake(preIntake.id as number, formData)
        .then(() => {
          toast.success(`Update Pre Intake.`);
          navigate('/pre-intake');
        })
        .catch(e => {
          toast.error(e?.data?.message || 'Something Went Wrong');
        })
        .finally(() => setLoading(false));
    } else {
      // If no existing PreIntake (creating), create a new one
      createPreIntake(formData)
        .then(() => {
          toast.success(`Create Pre Intake.`);
          navigate('/pre-intake');
        })
        .catch(e => {
          toast.error(e?.data?.message || 'Something Went Wrong');
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      {/* Main layout with two columns: Sidebar and PreIntake form */}
      <Row>
        {/* Sidebar Column (Left) */}
        <Col md={3}>
          <Stack gap={5}>
            <Card className="border-0 shadow bg-gradient--blue rounded-3">
              <Card.Body>
                <div className="d-flex  flex-column gap-4">
                  {/* User Avatar and Info */}
                  <div className="d-flex align-items-center gap-3">
                    <Avatar
                      size="3xl"
                      src={generic66}
                      className="d-inline-block"
                    />
                    <div>
                      <p className="text-white mb-1 opacity-50">
                        {t('welcome')}&nbsp;{user?.user_role?.display_name}
                      </p>
                      <h3 className="text-white fw-bolder mb-0">
                        {user?.name}
                      </h3>
                    </div>
                  </div>

                  {/* Navigation link to "My Tasks" */}
                  <Link
                    to="#!"
                    className="btn btn-phoenix-primary rounded-pill d-flex align-items-center"
                  >
                    <ListViewIcon size="16" className="me-2" />
                    {t('my_tasks')}
                  </Link>
                </div>
              </Card.Body>
            </Card>
          </Stack>
        </Col>

        {/* Main Form Column (Right) */}
        <Col md={9}>
          <h3 className="mb-5">
            {/* Show "Edit Pre Intake" or "Add New Pre Intake" based on whether 'preIntake' is available */}
            {preIntake?.id ? t('edit') : t('add_new')}&nbsp;{t('pre_intake')}
          </h3>
          <Stack gap={5}>
            <Card className="border-0 shadow rounded-3">
              <Card.Body>
                {/* PreIntake form component, passing 'preIntake', 'loading', and 'handleOnSubmit' as props */}
                <PreIntakeForm
                  preIntake={preIntake}
                  loading={loading}
                  onSubmit={handleOnSubmit}
                />
              </Card.Body>
            </Card>
          </Stack>
        </Col>
      </Row>
    </>
  );
};

export default PreIntakeAdd;
