import { useDispatch, useSelector } from 'store';
import {
  getAllIssue,
  getOneIssue,
  addOneIssue
} from 'store/reducers/issueSlice';
import { TFilterIssue, TIssue } from 'types/issues';
import IssueService from 'services/IssueService';

const useIssueHook = () => {
  const dispatch = useDispatch();
  const { issues, issueDetail, meta } = useSelector(state => state.issue);

  const fetchAllIssue = async (filters: TFilterIssue): Promise<TIssue[]> => {
    try {
      const resp = await IssueService.fetchAll(filters);
      const results: TIssue[] = resp?.data?.rows || [];
      const meta = resp?.data?.meta || {};
      dispatch(getAllIssue({ results, meta }));
      return results;
    } catch (e) {
      dispatch(getAllIssue({ results: [], meta: {} }));
      console.log('getAllIssue', e);
      throw e;
    }
  };

  const fetchOneIssue = async (id: string): Promise<TIssue> => {
    try {
      const resp = await IssueService.fetchOne(id);
      const row = resp?.data?.data[0] || null;

      dispatch(getOneIssue({ row }));
      return row;
    } catch (e) {
      dispatch(getOneIssue({ row: null }));
      console.log('getOneIssue', e);
      throw e;
    }
  };

  const createIssue = async (data: TIssue): Promise<TIssue> => {
    try {
      const resp = await IssueService.create(data);
      const row = resp?.data?.data || null;

      dispatch(addOneIssue({ row }));
      return row;
    } catch (e) {
      console.log('addOneIssue', e);
      throw e;
    }
  };

  return {
    fetchAllIssue,
    fetchOneIssue,
    createIssue,

    meta,
    issues,
    issueDetail
  };
};

export default useIssueHook;
