import { faChevronRight, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Button from 'components/base/Button';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';
import { TForgotPassword } from '../../../types/auth';
import AlertMessage from '../../common/AlertMessage';
import useAuthHook from '../../../hooks/modules/useAuthHook';
import { useState } from 'react';
import { TAlert } from '../../../types';
import { ForgotPasswordSchema } from '../../../validation-schema/AuthSchema';

const ForgotPasswordForm = ({
  layout
}: {
  layout?: 'simple' | 'card' | 'split';
}) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  const { forgotPassword } = useAuthHook();
  const [alert, setAlert] = useState<TAlert | null>(null);
  const initialValues: { to: string } = {
    to: ''
  };
  const [loader, setLoader] = useState<{ form?: boolean }>({
    form: false
  });

  /**
   * Send forgot password link to email to reset password
   * @param values
   * @param resetForm
   */
  const onSubmit = async (
    values: { to: string },
    { resetForm }: FormikHelpers<{ to: string }>
  ) => {
    const data: TForgotPassword = {
      to: values.to,
      reset_url: process.env.REACT_APP_PASSWORD_LINK || ''
    };
    setLoader({ form: true });
    forgotPassword(data)
      .then(() => {
        setLoader({ form: false });
        resetForm();
        setAlert({
          type: 'SUCCESS',
          message: t('forgot_your_password_message_success')
        });
      })
      .catch(e => {
        if (e?.response?.status == '404') {
          setAlert({
            type: 'ERROR',
            message: t('message_not_found', { name: t('user') })
          });
        } else {
          setAlert({ type: 'ERROR', message: t('message_failed') });
        }
        setLoader({ form: false });
      });
  };
  return (
    <div className={classNames({ 'px-xxl-5': !(layout === 'split') })}>
      <div
        className={classNames('text-center', { 'mb-6': !(layout === 'split') })}
      >
        <h4 className="text-body-highlight">
          {t('forgot_your_password_title')}
        </h4>
        <p className="text-body-tertiary mb-5">
          {t('forgot_your_password_sub_title')}
        </p>

        {alert && <AlertMessage type={alert.type} message={alert.message} />}
        <Formik
          initialValues={initialValues}
          validationSchema={ForgotPasswordSchema()}
          onSubmit={onSubmit}
        >
          {({
            isSubmitting,
            values,
            handleBlur,
            handleChange,
            errors,
            touched,
            handleSubmit
          }) => (
            <Form
              noValidate
              onSubmit={handleSubmit}
              className="d-flex align-items-center flex-column gap-3 mb-2"
            >
              <div className="form-icon-container w-100 text-start">
                <Form.Control
                  id="email"
                  type="email"
                  name="to"
                  className={`form-control form-icon-input ${
                    touched.to && errors.to ? 'is-invalid' : ''
                  }`}
                  placeholder={`${t('enter')} ${t('email')}`}
                  value={values.to}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />

                {touched.to && errors.to && (
                  <Form.Control.Feedback type="invalid">
                    {errors.to}
                  </Form.Control.Feedback>
                )}

                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="text-body fs-9 form-icon"
                />
              </div>
              <Button
                type="submit"
                variant="primary"
                className="ms-2"
                endIcon={
                  <FontAwesomeIcon icon={faChevronRight} className="ms-2" />
                }
                disabled={isSubmitting || loader.form}
              >
                {t('send')}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
