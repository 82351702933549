import { useDispatch, useSelector } from '../../../store';
import {
  TFilterPreIntake,
  TPreIntake,
  TPreIntakeCount
} from '../../../types/intake';
import PreIntakeService from '../../../services/intake/PreIntakeService';
import {
  createOnePreIntake,
  getAllPreIntake,
  getOnePreIntake,
  removeOnePreIntake,
  updateOnePreIntake,
  getPreIntakeCount
} from '../../../store/reducers/intake/preIntakeSlice';

const usePreIntakeHook = () => {
  const dispatch = useDispatch();
  const { preIntakes, preIntake, meta, count } = useSelector(
    state => state.preIntake
  );

  const fetchAllPreIntake = async (
    filters: TFilterPreIntake
  ): Promise<TPreIntake[]> => {
    try {
      const resp = await PreIntakeService.fetchAll(filters);
      const meta = resp?.data?.data?.meta || {};
      const results: TPreIntake[] = resp?.data?.data?.rows || [];
      dispatch(getAllPreIntake({ results, meta }));
      return results;
    } catch (e) {
      dispatch(getAllPreIntake({ results: [], meta: {} }));
      console.log('fetchAllPreIntake', e);
      throw e;
    }
  };

  const fetchOnePreIntake = async (id: number): Promise<TPreIntake> => {
    try {
      const resp = await PreIntakeService.fetchOne(id);
      const row = resp?.data?.data || null;

      dispatch(getOnePreIntake({ row }));
      return row;
    } catch (e) {
      dispatch(getOnePreIntake({ row: null }));
      console.log('fetchOnePreIntake', e);
      throw e;
    }
  };

  const createPreIntake = async (data: TPreIntake): Promise<TPreIntake> => {
    try {
      const resp = await PreIntakeService.create(data);
      const row = resp?.data?.data || null;

      dispatch(createOnePreIntake({ row }));
      return row;
    } catch (e) {
      console.log('createPreIntake', e);
      throw e;
    }
  };

  const updatePreIntake = async (id: number, data: TPreIntake) => {
    try {
      await PreIntakeService.update(id, data);

      dispatch(updateOnePreIntake({ id, row: data }));
      return data;
    } catch (e) {
      console.log('updatePreIntake', e);
      throw e;
    }
  };

  const deletePreIntake = async (id: number) => {
    try {
      await PreIntakeService.destroy(id);
      dispatch(removeOnePreIntake({ id }));
    } catch (e) {
      console.log('deletePreIntake', e);
      throw e;
    }
  };

  const fetchPreIntakeCount = async (): Promise<TPreIntakeCount> => {
    try {
      const resp = await PreIntakeService.fetchCount();
      const row = resp?.data?.data || null;
      dispatch(getPreIntakeCount({ row }));
      return row;
    } catch (e) {
      console.log('fetchPreIntakeCount', e);
      throw e;
    }
  };

  return {
    fetchAllPreIntake,
    fetchOnePreIntake,
    createPreIntake,
    updatePreIntake,
    deletePreIntake,
    fetchPreIntakeCount,
    preIntakes,
    meta,
    count,
    preIntake
  };
};

export default usePreIntakeHook;
