import { createSlice } from '@reduxjs/toolkit';
import { TMeta } from 'types';
import { TIssue } from 'types/issues';

interface IssueState {
  issues: TIssue[];
  issueDetail: TIssue | null;
  meta: TMeta;
}

const initialState: IssueState = {
  issues: [],
  issueDetail: <TIssue>{},
  meta: <TMeta>{}
};

const issueSlice = createSlice({
  name: 'issue',
  initialState,
  reducers: {
    getAllIssue(state, action) {
      state.issues = action.payload.results || [];
      state.meta = action.payload.meta || {};
    },
    getOneIssue(state, action) {
      state.issueDetail = action.payload.row || null;
    },
    addOneIssue(state, action) {
      state.issues = [...state.issues, action.payload.row];
    }
  }
});

export const { getAllIssue, addOneIssue, getOneIssue } = issueSlice.actions;
export default issueSlice.reducer;
