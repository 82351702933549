import { useEffect, useState } from 'react';
import { Card, Col, Row, Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarDay,
  faEnvelope,
  faMapLocation,
  faPeopleGroup,
  faPhone,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import usePreIntakeHook from '../../../hooks/modules/pre-intake/usePreIntakeHook';
import { toUcFirst } from '../../../helpers/utils';
import PhoenixLoader from '../../../components/common/PhoenixLoader';
import { genders } from '../../../data';
import { TPreIntake, TPreIntakeValidation } from '../../../types/intake';
import usePreIntakeValidationHook from '../../../hooks/modules/pre-intake/usePreIntakeValidationHook';
import { toast } from 'react-toastify';
import PreIntakeHistory from './history';
import usePreIntakeHistoryHook from '../../../hooks/modules/pre-intake/usePreIntakeHistoryHook';
import PreIntakeValidationForm from '../../../components/modules/pre-intake/forms/PreIntakeValidationForm';
import { checkScope } from '../../../helpers/auth';
import { useTranslation } from 'react-i18next';
import PreIntakeForm from '../../../components/modules/pre-intake/forms/PreIntakeForm';
import PreIntakeDetailInValidation from './detail';
import PageBreadcrumb, {
  PageBreadcrumbItem
} from '../../../components/common/PageBreadcrumb';

/**
 * Validated/Rejected pre-intake case with history
 * @returns {JSX.Element}
 * @constructor
 */
const PreIntakeValidation = () => {
  // UseTranslation hook to handle translation for multi-language support
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();

  // Navigate hook from react-router-dom to navigate programmatically between pages
  const navigate = useNavigate();

  // Breadcrumb items for navigation, representing a path in the application
  const preIntakeValidationBreadcrumbItems: PageBreadcrumbItem[] = [
    {
      label: t('list_of_pre_intake'),
      url: '/pre-intake'
    },
    {
      label: t('validation'),
      active: true
    }
  ];

  // useEffect to check if the current user has the necessary scope ('pre-intake-update')
  // If not, the user is redirected to the 403 error page
  useEffect(() => {
    if (!checkScope('pre-intake-update')) navigate('/error/403');
  }, []);

  // Extract `id` from the URL parameters (used to fetch a specific Pre Intake)
  const { id } = useParams();

  // useState to hold the ID of the current Pre Intake
  const [preIntakeId, setPreIntakeId] = useState<number | undefined>(undefined);

  // Custom hooks for fetching and updating Pre Intake data
  const { fetchOnePreIntake, updatePreIntake } = usePreIntakeHook();

  // useState to store the Pre Intake data
  const [preIntake, setPreIntake] = useState<TPreIntake | null>(null);

  // Custom hook for creating Pre Intake validation
  const { createPreIntakeValidation } = usePreIntakeValidationHook();

  // Custom hook for fetching Pre Intake history
  const { fetchAllPreIntakeHistory, preIntakeHistories } =
    usePreIntakeHistoryHook();

  // useState to manage the loading state of the component
  const [loading, setLoading] = useState(false);

  // useEffect to update `preIntakeId` when the `id` param changes
  useEffect(() => {
    if (id) {
      setPreIntakeId(parseInt(id));
    }
  }, [id]);

  // useEffect to fetch data when `preIntakeId` is set
  useEffect(() => {
    if (preIntakeId) {
      fetchOne();
      fetchHistory();
    }
  }, [preIntakeId]);

  // Function to fetch a single Pre Intake by ID
  const fetchOne = () => {
    setLoading(true);
    fetchOnePreIntake(preIntakeId as number)
      .then(row => setPreIntake(row))
      .catch(e => console.log(e))
      .finally(() => setLoading(false));
  };

  // Function to fetch Pre Intake history
  const fetchHistory = () => {
    setLoading(true);
    fetchAllPreIntakeHistory(preIntakeId as number)
      .catch(e => console.log(e))
      .finally(() => setLoading(false));
  };

  // Handler function for submitting Pre Intake validation form
  const handleOnSubmit = (values: TPreIntakeValidation) => {
    createPreIntakeValidation(preIntake?.id as number, values)
      .then(() => {
        toast.success(
          `${toUcFirst(values.decision as string)} (${preIntake?.pre_intake_case_id})`
        );
        if (values.decision === 'save_to_history') {
          fetchHistory();
        } else {
          // navigate('/pre-intake');
          fetchOne();
        }
      })
      .catch(e => {
        console.log(e);
        toast.error(e?.data?.message || 'Something Went Wrong');
      });
  };

  // Handler function for submitting Pre Intake form
  const handleOnPreIntakeSubmit = (formData: TPreIntake) => {
    setLoading(true);
    if (preIntake) {
      updatePreIntake(preIntake.id as number, formData)
        .then(row => {
          toast.success(`Save Pre Intake.`);
          setPreIntake(row);
        })
        .catch(e => {
          toast.error(e?.data?.message || 'Something Went Wrong');
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      {/* Breadcrumbs for navigation */}
      <Row className="mb-2">
        <Col>
          <PageBreadcrumb items={preIntakeValidationBreadcrumbItems} />
        </Col>
      </Row>

      <Row>
        {/* Left column for displaying Pre Intake details */}
        <Col md={3}>
          <Stack gap={5} className="mb-5">
            <Card className="border-0 shadow bg-gradient--blue rounded-3">
              <Card.Body>
                {/* Show Pre Intake details if not loading, otherwise show loading state */}
                {!loading ? (
                  <>
                    <div className="d-flex  align-items-center justify-content-between mb-2">
                      <h3 className="text-white fw-bolder mb-0">
                        {preIntake?.individual_first_name +
                          ' ' +
                          preIntake?.individual_last_name}
                      </h3>
                      <div className="d-flex align-items-center gap-3"></div>
                    </div>{' '}
                    <Stack gap={2}>
                      {/* Displaying different Pre Intake details */}
                      <Row className="g-1">
                        <Col md={12}>
                          <div>
                            <FontAwesomeIcon
                              icon={faUser}
                              className="me-2 text-body-quaternary fs-9"
                            />
                            <span className="text-white">
                              {preIntake?.individual_gender
                                ? t(
                                    genders.filter(
                                      data =>
                                        data.value ==
                                        preIntake.individual_gender
                                    )?.[0]?.label
                                  )
                                : 'N/A'}
                            </span>
                          </div>
                        </Col>
                        <Col md={12}>
                          <div>
                            <FontAwesomeIcon
                              icon={faPhone}
                              className="me-2 text-body-quaternary fs-9"
                            />
                            <span className="text-white">
                              {preIntake?.individual_phone_number || 'N/A'}
                            </span>
                          </div>
                        </Col>
                        <Col md={12}>
                          <div>
                            <FontAwesomeIcon
                              icon={faEnvelope}
                              className="me-2 text-body-quaternary fs-9"
                            />
                            <span className="text-white">
                              {' '}
                              {preIntake?.individual_email || 'N/A'}
                            </span>
                          </div>
                        </Col>
                        <Col md={12}>
                          <div>
                            <FontAwesomeIcon
                              icon={faPeopleGroup}
                              className="me-2 text-body-quaternary fs-9"
                            />
                            <span className="text-white">
                              {' '}
                              {preIntake?.individual_age_range
                                ? t(preIntake.individual_age_range)
                                : 'N/A'}
                            </span>
                          </div>
                        </Col>
                        <Col md={12}>
                          <div>
                            <FontAwesomeIcon
                              icon={faMapLocation}
                              className="me-2 text-body-quaternary fs-9"
                            />
                            <span className="text-white">
                              {preIntake?.individual_address || 'N/A'}
                            </span>
                          </div>
                        </Col>
                        <Col md={12}>
                          <div>
                            <FontAwesomeIcon
                              icon={faCalendarDay}
                              className="me-2 text-body-quaternary fs-9"
                            />
                            <span className="text-white">
                              {preIntake?.pre_intake_date || 'N/A'}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </Stack>
                  </>
                ) : (
                  <PhoenixLoader />
                )}
              </Card.Body>
            </Card>

            {/* Display Pre Intake history */}
            <Row>
              <Col md={12}>
                <PreIntakeHistory
                  preIntakeHistories={preIntakeHistories}
                  loading={loading}
                />
              </Col>
            </Row>
          </Stack>
        </Col>

        {/* Right column for displaying form or validation details */}
        <Col md={9}>
          <Stack gap={5}>
            <Card className="border-0 shadow rounded-3">
              <Card.Body>
                {/* If Pre Intake is not validated, show Pre Intake form, otherwise show validation details */}
                {preIntake && (
                  <>
                    {!(
                      preIntake?.status == 'validated' ||
                      preIntake?.status == 'rejected'
                    ) ? (
                      <PreIntakeForm
                        loading={loading}
                        preIntake={preIntake}
                        onSubmit={handleOnPreIntakeSubmit}
                      />
                    ) : (
                      <PreIntakeDetailInValidation
                        preIntake={preIntake}
                        loading={loading}
                      />
                    )}
                  </>
                )}
              </Card.Body>
            </Card>

            {/* Conditional rendering based on Pre Intake status */}
            {preIntake?.status && (
              <Card className="border-0 shadow rounded-3">
                <Card.Body>
                  {/* If validated or rejected, show findings and conclusion */}
                  <h6 className="mb-4">{t('validators_decision')}?</h6>
                  {preIntake?.status == 'validated' ||
                  preIntake?.status == 'rejected' ? (
                    <>
                      <div className="mb-3">
                        <Row>
                          <Col md={4}>
                            <h6> {t('findings')}</h6>
                            <span>
                              {preIntake?.validation_details?.findings}
                            </span>
                          </Col>
                          <Col md={4}>
                            <h6>{t('conclusion')}</h6>
                            <span>
                              {preIntake?.validation_details?.conclusion}
                            </span>
                          </Col>
                        </Row>
                      </div>
                    </>
                  ) : (
                    <Card className="border-0 shadow rounded-3">
                      <Card.Body>
                        {/* If not validated/rejected, show Pre Intake validation form */}
                        <PreIntakeValidationForm
                          onSubmit={handleOnSubmit}
                          loading={loading}
                          preIntake={preIntake as TPreIntake}
                        />
                      </Card.Body>
                    </Card>
                  )}
                </Card.Body>
              </Card>
            )}
          </Stack>
        </Col>
      </Row>
    </>
  );
};

export default PreIntakeValidation;
