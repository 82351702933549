import React, {useState} from 'react';
import track from 'assets/img/track.png';
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {Formik} from 'formik';
import {TrackCaseSchema} from '../../validation-schema';

import PublicLayout from '../../layouts/PublicLayout';
import {useTranslation} from 'react-i18next';
import {TTrackCase} from '../../types/cases';
import useCaseHook from '../../hooks/modules/case/useCaseHook';
import {useNavigate} from 'react-router-dom';
import AlertMessage from '../../components/common/AlertMessage';
import {TAlert} from '../../types';

// Initial values
const initialValues: TTrackCase = {
    individual_first_name: '',
    pre_intake_case_id: '',
}

/**
 * Component: TrackMyCase
 * Purpose: Renders a form to track a user's case using their first name and case ID.
 *          Validates input fields and handles case tracking using provided hooks.
 */
const TrackMyCase = () => {
    // Translation hook for multi-language support
    // eslint-disable-next-line
    const {t}: { t: any } = useTranslation();
    
    // Custom hook to handle case-related actions
    const {trackOneCase} = useCaseHook();

    // React Router hook to navigate between pages
    const navigate = useNavigate();
    const [alert, setAlert] = useState<TAlert | null>(null);
    const [loading, setLoading] = useState(false);

    /**
     * Function: handleOnSubmit
     * Purpose: Handles form submission by tracking the case based on user input.
     * @param {TTrackCase} formData - The form data containing the case ID and first name.
     */
    const handleOnSubmit = (formData: TTrackCase) => {
        setLoading(true);
        trackOneCase(formData)
            .then((data) => {
                navigate('/track-results', {state: {trackCase: data}})
            })
            .catch(e => {
                setAlert({
                    type: 'ERROR',
                    message: `Not Found Case ID (${formData.pre_intake_case_id}) `
                });
                console.log(e);
            })
            .finally(() => setLoading(false));
    };

    return (
        <PublicLayout>
            {/* Full-page layout with alignment */}
            <div className='d-flex align-items-center h-100'>
                <Container>
                    <Row className='d-flex align-items-center'>
                        {/* Left Column: Displays an image */}
                        <Col md={6}>
                            <img src={track} alt="track my case" className="w-100"/>
                        </Col>

                        {/* Right Column: Displays form and title */}
                        <Col md={6}>
                            <div className="d-flex flex-column gap-2 border-for-title">
                                <h1> {t('track_your_case')}</h1>
                                <p>
                                    {t('stay_informed')}
                                </p>
                            </div>

                            {/* Formik wrapper for form handling */}
                            <Formik
                                initialValues={initialValues}
                                validationSchema={TrackCaseSchema}
                                onSubmit={handleOnSubmit}
                            >
                                {({
                                      values,
                                      handleBlur,
                                      handleChange,
                                      errors,
                                      touched,
                                      handleSubmit
                                  }) => {
                                    return (
                                        <Form noValidate onSubmit={handleSubmit}>
                                            {/* Display alert message if present */}
                                            {alert && <AlertMessage type={alert.type} message={alert.message}/>}
                                            
                                            <Row>
                                                {/* Case ID Field */}
                                                <Col md={12}>
                                                    <Form.Group className="mb-3" controlId="formGroupFirstName">
                                                        <Form.Label>{t('pre_case_id')}</Form.Label>
                                                        <Form.Control
                                                            className={`${
                                                                touched.pre_intake_case_id &&
                                                                errors.pre_intake_case_id
                                                                    ? 'is-invalid'
                                                                    : ''
                                                            }`}
                                                            type="text"
                                                            name="pre_intake_case_id"
                                                            placeholder={t('example_pre_case_id')}
                                                            value={values.pre_intake_case_id || ''}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                        />

                                                        {/* Validation error message */}
                                                        {touched.pre_intake_case_id &&
                                                            errors.pre_intake_case_id && (
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.pre_intake_case_id}
                                                                </Form.Control.Feedback>
                                                            )}
                                                    </Form.Group>
                                                </Col>

                                                {/* First Name Field */}
                                                <Col md={12}>
                                                    <Form.Group className="mb-3" controlId="formGroupLastName">
                                                        <Form.Label>{t('first_name')}</Form.Label>
                                                        <Form.Control
                                                            className={`${
                                                                touched.individual_first_name &&
                                                                errors.individual_first_name
                                                                    ? 'is-invalid'
                                                                    : ''
                                                            }`}
                                                            type="text"
                                                            name="individual_first_name"
                                                            placeholder={t('example_first_name')}
                                                            value={values.individual_first_name || ''}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                        />

                                                        {/* Validation error message */}
                                                        {touched.individual_first_name &&
                                                            errors.individual_first_name && (
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.individual_first_name}
                                                                </Form.Control.Feedback>
                                                            )}
                                                    </Form.Group>
                                                </Col>

                                                {/* Submit Button */}
                                                <Col md={12}>
                                                    <Button type="submit" disabled={loading} variant="warning" className='rounded-pill w-100 mb-4 mt-2'>
                                                        {t('start_tracking')}
                                                        <FontAwesomeIcon icon={faChevronRight} className="ps-2"/>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    );
                                }}
                            </Formik>
                            <Form>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </PublicLayout>
    );
};

export default TrackMyCase;
