import { RouteObject } from 'react-router-dom';
import MainLayoutProvider from '../providers/MainLayoutProvider';
import AuthGuard from '../utils/route-guard/AuthGuard';
import MainLayout from '../layouts/MainLayout';
import { lazy, Suspense } from 'react';
import PhoenixLoader from '../components/common/PhoenixLoader';

const Issues = lazy(() => import('pages/issues'));

const IssueRoutes: RouteObject = {
  path: '/issues',
  element: (
    <MainLayoutProvider>
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    </MainLayoutProvider>
  ),
  children: [
    {
      path: '',
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <Issues />
        </Suspense>
      )
    }
  ]
};

export default IssueRoutes;
