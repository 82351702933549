export const onBehalfArray = [
  {
    label: 'Myself',
    value: 'myself'
  },
  {
    label: 'Others',
    value: 'others'
  },
  {
    label: 'Group',
    value: 'group'
  }
];
export const genders = [
  {
    label: 'male',
    value: 'M'
  },
  {
    label: 'female',
    value: 'F'
  }
];

export const ageRanges = [
  {
    label: 'old',
    value: 'old'
  },
  {
    label: 'adult',
    value: 'adult'
  },
  {
    label: 'child',
    value: 'child'
  }
];

export const statuses = [
  {
    label: 'Active',
    value: 'active'
  },
  {
    label: 'InActive',
    value: 'in_active'
  }
];
export const recipients = [
  {
    label: 'Individual',
    value: 1
  },
  {
    label: 'Family',
    value: 2
  },
  {
    label: 'Group',
    value: 3
  }
];

export const flags = [
  {
    shortLabel: 'IC',
    label_translation_key: 'incoming',
    value: 1
  },
  {
    shortLabel: 'MS',
    label_translation_key: 'matched_and_sent',
    value: 2
  },
  {
    shortLabel: 'MA',
    label_translation_key: 'matched_and_accepted',
    value: 3
  },
  {
    shortLabel: 'MR',
    label_translation_key: 'matched_and_reassessed',
    value: 4
  },
  {
    shortLabel: 'GOC',
    label_translation_key: 'grievance_or_complaint',
    value: 5
  },
  {
    shortLabel: 'CC',
    label_translation_key: 'case_closure',
    value: 6
  }
];
