import { createSlice } from '@reduxjs/toolkit';
import { TLocationLevel } from '../../../types/mdm';

interface LocationLevelState {
  locationLevels: TLocationLevel[];
}

const initialState: LocationLevelState = {
  locationLevels: []
};

const locationLevelSlice = createSlice({
  name: 'locationLevel',
  initialState,
  reducers: {
    setAllLocationLevel(state, action) {
      state.locationLevels = action.payload.results || [];
    },
    addOneLocationLevel(state, action) {
      state.locationLevels = [...state.locationLevels, action.payload.row];
    },
    setOneLocationLevel(state, action) {
      state.locationLevels = state.locationLevels.map(item => {
        if (item.id !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },
    removeOneLocationLevel(state, action) {
      state.locationLevels = state.locationLevels.filter(
        item => item.id !== action.payload.id
      );
    }
  }
});

export const {
  setAllLocationLevel,
  addOneLocationLevel,
  setOneLocationLevel,
  removeOneLocationLevel
} = locationLevelSlice.actions;
export default locationLevelSlice.reducer;
