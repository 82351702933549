import { createSlice } from '@reduxjs/toolkit';
import { TMeta } from '../../../types';
import { TCase, TCaseCount } from '../../../types/cases';

interface CaseState {
  cases: TCase[];
  caseDetail: TCase;
  meta: TMeta;
  count: TCaseCount;
}

const initialState: CaseState = {
  cases: [],
  caseDetail: <TCase>{},
  meta: <TMeta>{},
  count: <TCaseCount>{}
};

const caseSlice = createSlice({
  name: 'case',
  initialState,
  reducers: {
    getAllCase(state, action) {
      state.cases = action.payload.results || [];
      state.meta = action.payload.meta || {};
    },
    getOneCase(state, action) {
      state.caseDetail = action.payload.row || null;
    },
    getCaseCount(state, action) {
      state.count = action.payload.row || {};
    }
  }
});

export const { getAllCase, getOneCase, getCaseCount } = caseSlice.actions;
export default caseSlice.reducer;
