import React from 'react';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons';
import { TAlert } from '../../types';

const AlertMessage = ({ type, message }: TAlert) => {
  if (type == 'SUCCESS') {
    return (
      <Alert variant="subtle-success" className="border-0 p-3 mb-0">
        <span className="text-success font-medium">
          <FontAwesomeIcon icon={faCheckCircle} className="me-2 text-success" />
          {message}
        </span>
      </Alert>
    );
  }

  if (type == 'ERROR') {
    return (
      <Alert variant="subtle-danger" className="border-0 p-3 mb-0">
        <span className="text-danger  font-medium">
          <FontAwesomeIcon
            className="me-2 text-danger"
            icon={faTriangleExclamation}
          />
          {message}
        </span>
      </Alert>
    );
  }
  if (type == 'WARNING') {
    return (
      <Alert variant="subtle-warning" className="border-0 p-3 mb-0">
        <span className="text-warning  font-medium">
          <FontAwesomeIcon
            className="me-2 text-danger"
            icon={faTriangleExclamation}
          />
          {message}
        </span>
      </Alert>
    );
  }
};

export default AlertMessage;
