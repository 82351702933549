import { createSlice } from '@reduxjs/toolkit';
import { TMeta } from 'types';
import { TCaseAssessment } from 'types/cases';

interface CaseAssessmentState {
  assessments: TCaseAssessment[];
  assessment: TCaseAssessment | null;
  meta: TMeta;
}

const initialState: CaseAssessmentState = {
  assessments: [],
  assessment: <TCaseAssessment>{},
  meta: <TMeta>{}
};

const assessmentSlice = createSlice({
  name: 'caseAssessment',
  initialState,
  reducers: {
    getAllAssessments(state, action) {
      state.assessments = action.payload.results || [];
      state.meta = action.payload.meta || {};
    },
    getOneAssessment(state, action) {
      state.assessment = action.payload.row || null;
    },
    createOneAssessment(state, action) {
      state.assessments = [...state.assessments, action.payload.row];
    }
  }
});

export const { getAllAssessments, getOneAssessment, createOneAssessment } =
  assessmentSlice.actions;
export default assessmentSlice.reducer;
