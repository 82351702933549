import ForgotPasswordForm from 'components/modules/auth/ForgotPasswordForm';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import bg from 'assets/img/member.png';

// ForgotPassword Component
// This component is responsible for rendering the Forgot Password page.
// It utilizes a split layout design and includes a form for users to reset their password.

const ForgotPassword = () => {
  return (
    // AuthSplitLayout is used as the wrapper component, which handles the layout structure
    // It receives the background image (bg) as a prop to customize the appearance of the page
    <AuthSplitLayout bg={bg}>
      {/* ForgotPasswordForm is the main form component for handling password reset */}
      {/* The 'layout' prop with the value "split" is passed to the ForgotPasswordForm */}
      {/* This indicates that the form should be displayed in a split layout style */}
      <ForgotPasswordForm layout="split" />
    </AuthSplitLayout>
  );
};

export default ForgotPassword;
