import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { TIssue } from 'types/issues';
import useFormOptionHook from 'hooks/modules/mdm/useFormOption';
import useCookies from '../../../../hooks/useCookiesHook';

interface IssueFormProps {
  selected: string;
  onClose: () => void;
}

const IssueForm = ({ selected, onClose }: IssueFormProps) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  const { formOptions } = useFormOptionHook();
  const { isEnglishLanguage } = useCookies();
  const formik = useFormikContext<TIssue>();

  const handleClose = () => {
    onClose();
    formik.resetForm();
    formik.setErrors({});
  };

  const issueTypes = React.useMemo(() => {
    return formOptions?.filter(data => data?.slug === 'complaint');
  }, [formOptions]);

  return (
    <React.Fragment>
      <Modal
        show={selected === 'add' || selected === 'update'}
        onHide={() => {
          handleClose();
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('file_a_complaint')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-4 g-3">
            <Col md={12}>
              <Form.Group controlId="full_name">
                <Form.Label>{t('full_name')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={`${t('enter')} ${t('full_name')}`}
                  name="full_name"
                  value={formik?.values?.full_name || ''}
                  onChange={formik.handleChange}
                  className={`${
                    formik?.touched?.full_name && formik?.errors?.full_name
                      ? 'is-invalid'
                      : ''
                  }`}
                />
                {formik.touched.full_name && formik.errors.full_name && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.full_name}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col sm={12}>
              <Form.Group controlId="type_of_complaint">
                <Form.Label>{t('type_of_complaint')}</Form.Label>
                <Form.Select
                  name="type"
                  value={formik?.values?.type || ''}
                  onChange={formik.handleChange}
                  className={`${
                    formik?.touched?.type && formik?.errors?.type
                      ? 'is-invalid'
                      : ''
                  }`}
                >
                  <option value="">{t('select_type')}</option>
                  {issueTypes?.map((data, index: number) => (
                    <option key={index} value={String(data?.id)}>
                      {isEnglishLanguage
                        ? data?.label
                        : data?.label_alt || data?.label}
                    </option>
                  ))}
                </Form.Select>
                {formik.touched.type && formik.errors.type && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.type}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col sm={12}>
              <Form.Group controlId="title">
                <Form.Label>{t('title')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={`${t('enter')} ${t('title')}`}
                  name="title"
                  value={formik?.values?.title || ''}
                  onChange={formik.handleChange}
                  className={`${
                    formik?.touched?.title && formik?.errors?.title
                      ? 'is-invalid'
                      : ''
                  }`}
                />
                {formik.touched.title && formik.errors.title && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.title}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col sm={12}>
              <Form.Group className="mb-3" controlId="description">
                <Form.Label>{t('description')}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="body"
                  value={formik?.values?.body || ''}
                  onChange={formik.handleChange}
                  className={`${
                    formik?.touched?.body && formik?.errors?.body
                      ? 'is-invalid'
                      : ''
                  }`}
                />
                {formik.touched.body && formik.errors.body && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.body}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="phoenix-secondary"
            className="rounded-pill"
            onClick={() => {
              handleClose();
            }}
          >
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            variant="success"
            className="rounded-pill"
            startIcon={<FontAwesomeIcon icon={faAdd} />}
            onClick={() => formik.handleSubmit()}
          >
            {selected === 'add' && t('add')}
            &nbsp;{t('complaint')}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default IssueForm;
