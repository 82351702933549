import ResetPasswordForm from 'components/modules/auth/ResetPasswordForm';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import bg from 'assets/img/member.png';

// ResetPassword Component
// This component is responsible for rendering the Reset Password page.
// It uses a split layout and includes a form for users to reset their password.

const ResetPassword = () => {
  return (
    // AuthSplitLayout is used as the wrapper component, providing a split layout for the page.
    // The 'bg' prop is passed to customize the background image for this page.
    <AuthSplitLayout bg={bg}>
      {/* ResetPasswordForm is the main form component for handling the password reset logic */}
      {/* This form will allow users to enter their new password and submit it for resetting */}
      <ResetPasswordForm />
    </AuthSplitLayout>
  );
};

export default ResetPassword;
