import { useMemo } from 'react';
import { TPreIntake } from '../../../../types/intake';
import { Col, Row } from 'react-bootstrap';
import Badge from '../../../../components/base/Badge';
import PhoenixLoader from '../../../../components/common/PhoenixLoader';
import useFormOptionHook from '../../../../hooks/modules/mdm/useFormOption';
import useCaseTypeHook from '../../../../hooks/modules/mdm/useCaseTypeHook';
import useLocationHook from '../../../../hooks/modules/mdm/useLocationHook';
import { Util } from 'leaflet';
import { useTranslation } from 'react-i18next';
import StatusBadge from '../../../../components/common/StatusBadge';
import useCookies from '../../../../hooks/useCookiesHook';
import isArray = Util.isArray;

//interfaces
interface PreIntakeDetailInValidationProps {
  preIntake: TPreIntake;
  loading: boolean;
}

/**

 * Displays detailed information about a pre-intake record that is in validation.
 * It includes information such as the relationship, address, location, reason for applying,
 * and case types associated with the pre-intake
 */
const PreIntakeDetailInValidation = ({
  loading,
  preIntake
}: PreIntakeDetailInValidationProps) => {
  // Translation hook for internationalization.
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  const { isEnglishLanguage } = useCookies();
  // Custom hooks for fetching form options, case types, and locations.
  const { formOptions } = useFormOptionHook();
  const { locations } = useLocationHook();

  // Memoizing the 'relationship' form options to avoid unnecessary recalculations.
  const relationships = useMemo(() => {
    return formOptions
      .filter(data => data?.slug == 'relationship')
      .map(data => {
        return {
          ...data,
          ...(!isEnglishLanguage && data.label_alt && { label: data.label_alt })
        };
      });
  }, [formOptions, isEnglishLanguage]);

  // Custom hook to fetch available case types.
  const { caseTypes } = useCaseTypeHook();

  /**
   * Memoized list of case names associated with the pre-intake record.
   * This takes the case type ID from `preIntake` and maps it to the corresponding case types.
   */
  const caseNames = useMemo(() => {
    let cases: string[] = [];
    if (preIntake?.case_type_id) {
      const caseTypeIds = JSON.parse(preIntake.case_type_id) || [];
      if (isArray(caseTypeIds)) {
        cases = caseTypes
          .filter(data => caseTypeIds.includes(data.id as number))
          .map(
            data =>
              `${isEnglishLanguage ? data.name : data.name_alt || data.name} - ${data.code}`
          );
      }
    }
    return cases;
  }, [preIntake?.case_type_id, caseTypes, isEnglishLanguage]);

  /**
   * Memoized relationship name based on the `individual_relationship` value from `preIntake`.
   * It fetches the corresponding label for the relationship.
   */
  const relationName = useMemo(() => {
    const relation = relationships.find(
      data => data.id == (preIntake?.individual_relationship as number)
    );
    return isEnglishLanguage
      ? relation?.label
      : relation?.label_alt || relation?.label;
  }, [relationships, preIntake, isEnglishLanguage]);

  /**
   * Memoized location data (province, district, commune, and village) based on the location IDs
   * from the `preIntake` object. It uses the `locations` hook to filter the relevant data.
   */
  const locationName = useMemo(() => {
    const province = locations.find(
      data => data.id == (preIntake.province as number)
    );
    const district = locations.find(
      data => data.id == (preIntake.district as number)
    );
    const commune = locations.find(
      data => data.id == (preIntake.commune as number)
    );
    const village = locations.find(
      data => data.id == (preIntake.village as number)
    );
    return {
      province: isEnglishLanguage
        ? province?.name
        : province?.name_alt || province?.name,
      district: isEnglishLanguage
        ? district?.name
        : district?.name_alt || district?.name,
      commune: isEnglishLanguage
        ? commune?.name
        : commune?.name_alt || commune?.name,
      village: isEnglishLanguage
        ? village?.name
        : village?.name_alt || village?.name
    };
  }, [caseTypes, preIntake, isEnglishLanguage]);

  return (
    <>
      {/* Render when the data is not loading */}
      {!loading ? (
        <>
          {/* Display the "on behalf of" badge and the status of the pre-intake */}
          <div className="mb-3">
            <Badge
              pill
              bg="success"
              variant="phoenix"
              className="border-0 p-1 me-2"
            >
              {t('on_behalf_of')}{' '}
              {preIntake?.on_behalf ? t(preIntake.on_behalf) : ''}
            </Badge>

            {/* Display the status of the pre-intake */}
            <StatusBadge status={preIntake?.status as string} />
          </div>

          {/* Display the relationship and address information */}

          <div className="mt-4">
            <Row>
              {preIntake?.on_behalf == 'others' && (
                <Col md={4}>
                  <h6> {t('relationship')}</h6>
                  <span>{relationName || 'N/A'}</span>
                </Col>
              )}

              <Col md={4}>
                <h6>{t('address')}</h6>
                <span>{preIntake?.individual_address || 'N/A'}</span>
              </Col>
            </Row>
          </div>

          {/* Display the location information (province, district, commune, village) */}
          <div className="mt-4">
            <Row>
              <Col md={4}>
                <h6> {t('province')}</h6>
                <span>{locationName?.province || 'N/A'}</span>
              </Col>
              <Col md={4}>
                <h6> {t('district')}</h6>
                <span>{locationName?.district || 'N/A'}</span>
              </Col>

              <Col md={4}>
                <h6> {t('commune')}</h6>
                <span>{locationName?.commune || 'N/A'}</span>
              </Col>
              <Col md={4}>
                <h6> {t('village')}</h6>
                <span>{locationName?.village || 'N/A'}</span>
              </Col>
            </Row>
          </div>

          {/* Display the reason for applying */}
          <div className="mt-4">
            <h4 className="mb-2">
              {' '}
              <h6> {t('reason_for_applying')}</h6>
            </h4>
            <p>{preIntake?.reason_for_apply || 'N/A'}</p>
          </div>

          {/* Display the case types associated with the pre-intake */}
          <div className="mt-4">
            <h4 className="mb-2">
              {' '}
              <h6> {t('case_type')}</h6>
            </h4>
            {caseNames.map((name, i) => (
              <Badge
                key={`caseNames-${i}`}
                bg="secondary"
                variant="phoenix"
                className="border-0 me-2"
              >
                {name}
              </Badge>
            ))}
          </div>
        </>
      ) : (
        //Show the loading spinner when the data is still loading
        <PhoenixLoader />
      )}
    </>
  );
};
export default PreIntakeDetailInValidation;
