import {useState} from 'react';
import register_img from 'assets/img/register-image.png';
import {Col, Row} from 'react-bootstrap';
import PublicLayout from '../../../layouts/PublicLayout';
import {TPreIntake} from '../../../types/intake';
import usePreIntakeHook from '../../../hooks/modules/pre-intake/usePreIntakeHook';
import RegisterCaseSuccess from './register-success';
import PreIntakeForm from '../../../components/modules/pre-intake/forms/PreIntakeForm';
import {useTranslation} from 'react-i18next';

/**
 * RegisterCase Component
 * 
 * This component handles the registration of a new case. It uses a public layout
 * and conditionally renders either the success message or a form for case registration.
 */
const RegisterCase = () => {
    // Hook to provide translations for multilingual support
    // eslint-disable-next-line
    const {t}: { t: any } = useTranslation();

    // Custom hook for managing pre-intake API operations
    const {createPreIntake} = usePreIntakeHook()

    // State management
    const [loading, setLoading] = useState(false);
    const [preIntake, setPreIntake] = useState<TPreIntake>();
    
    /**
     * Resets the form state to allow for a new registration.
     * This function is called when the user clicks a "reset" button
     * on the success screen after registering a case.
     */
    const handleOnReset = () => {
        setPreIntake(undefined)
    }

    /**
    * Handles form submission for registering a case.
    * 
    * @param {TPreIntake} formData - The data submitted from the pre-intake form.
    */
    const handleOnSubmit = (formData: TPreIntake) => {
        setLoading(true);
        createPreIntake(formData)
            .then((data) => {
                setPreIntake(data)
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <PublicLayout>
                <Row>
                    {/* Left Column: Displays an illustrative image */}
                    <Col md={6}>
                        <img src={register_img} alt="A Man" className="w-100"/>
                    </Col>

                    {/* Right Column: Contains the registration form or success message */}
                    <Col md={6}>
                        <div className="d-flex flex-column gap-2 border-for-title">
                            <h1>{t("register_your_case")}</h1>
                            <h4 className="text-success">{t("with_isscms")}</h4>
                            <p>
                                {t("safe_confidential_and_compassionate_support")}
                            </p>
                        </div>

                        {/* Conditional Rendering: Display either success message or registration form */}
                        {preIntake ? <RegisterCaseSuccess onReset={handleOnReset} preIntake={preIntake}/> : <PreIntakeForm preIntake={preIntake} loading={loading} onSubmit={handleOnSubmit}/>}
                    </Col>
                </Row>
            </PublicLayout>
        </>
    );
};

export default RegisterCase;
