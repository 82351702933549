import Cookies from 'universal-cookie';

const cookies = new Cookies();

const useCookies = () => {
  const i18next = cookies.get('i18next') || 'en';
  const isEnglishLanguage = i18next == 'en';
  return {
    i18next,
    isEnglishLanguage
  };
};

export default useCookies;
