import api from '../../utils/api';
import { TFilterPreIntake, TPreIntake } from '../../types/intake';
import axios from 'axios';
import { serializedObject } from '../../helpers/utils';

const authEndpoint: string = `${process.env.REACT_APP_ENDPOINT}/case/intake/pre-intake`;

const fetchAll = (filters: TFilterPreIntake) => {
  return api.get(`${authEndpoint}?${serializedObject(filters)}`);
};
const fetchOne = (id: number) => {
  return api.get(`${authEndpoint}/${id}`);
};
const create = (data: TPreIntake) => {
  return axios.post(`${authEndpoint}`, data);
};
const update = (id: number, data: TPreIntake) => {
  return api.put(`${authEndpoint}/${id}`, data);
};
const destroy = (id: number) => {
  return api.delete(`${authEndpoint}/${id}`);
};
const fetchCount = () => {
  return api.get(`${authEndpoint}/getCount`);
};

export default {
  fetchAll,
  fetchOne,
  create,
  update,
  destroy,
  fetchCount
};
