import Button from 'components/base/Button';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { Link, useSearchParams } from 'react-router-dom';
import AlertMessage from '../../common/AlertMessage';
import { TAlert } from '../../../types';
import useAuthHook from '../../../hooks/modules/useAuthHook';
import { TResetPassword } from '../../../types/auth';
import { ResetPasswordSchema } from '../../../validation-schema/AuthSchema';

interface ResetFormPassword {
  password: string;
  confirm_password: string;
}

const ResetPasswordForm = () => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  const [queryParameters] = useSearchParams();
  const { resetPassword } = useAuthHook();
  const [alert, setAlert] = useState<TAlert | null>(null);
  const initialValues: ResetFormPassword = {
    password: '',
    confirm_password: ''
  };
  const [loader, setLoader] = useState<{ form?: boolean }>({
    form: false
  });

  /**
   * Set new password which was redirect from forgot password link which was sent through mail.
   * @param values
   */

  const onSubmit = async (
    values: ResetFormPassword,
    { resetForm }: FormikHelpers<ResetFormPassword>
  ) => {
    const email = queryParameters.get('email') || '';
    const token = queryParameters.get('token') || '';
    resetForm();
    if (!email && !token) {
      setAlert({
        type: 'ERROR',
        message: t('message_email_or_token_missing')
      });
      return;
    }
    const data: TResetPassword = {
      email,
      token,
      new_password: values.password
    };
    setLoader({ form: true });

    resetPassword(data)
      .then(() => {
        setLoader({ form: false });
        setAlert({
          type: 'SUCCESS',
          message: t('reset_password_message_success')
        });
      })
      .catch(e => {
        setLoader({ form: false });
        if (e?.response?.status === 404) {
          setAlert({
            type: 'ERROR',
            message: t('message_not_found', { name: t('user') })
          });
        } else {
          setAlert({ type: 'ERROR', message: t('message_failed') });
        }
      });
  };

  return (
    <div className="text-center mb-6">
      <h4 className="text-body-highlight">{t('reset_new_password_title')}</h4>
      <p className="text-body-tertiary">{t('reset_new_password_sub_title')}</p>

      <Formik
        initialValues={initialValues}
        validationSchema={ResetPasswordSchema()}
        onSubmit={onSubmit}
      >
        {({
          values,
          handleBlur,
          handleChange,
          errors,
          touched,
          handleSubmit
        }) => (
          <Form
            className="mt-5 mb-2 text-start"
            noValidate
            onSubmit={handleSubmit}
          >
            {alert && (
              <AlertMessage type={alert.type} message={alert.message} />
            )}
            <Form.Group className="mb-3 ">
              <Form.Label htmlFor="confirm_password">
                {t('password')}
              </Form.Label>
              <div className="form-icon-container mb-2">
                <Form.Control
                  id="password"
                  type="password"
                  name="password"
                  className={`form-control form-icon-input ${
                    touched.password && errors.password ? 'is-invalid' : ''
                  }`}
                  placeholder={`${t('enter')} ${t('password')}`}
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  autoComplete="true"
                />
                {touched.password && errors.password && (
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                )}
                <FontAwesomeIcon
                  icon={faKey}
                  className="text-body fs-9 form-icon"
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3 ">
              <Form.Label htmlFor="confirm_password">
                {t('confirm_password')}
              </Form.Label>
              <div className="form-icon-container mb-4">
                <Form.Control
                  id="confirm_password"
                  type="password"
                  name="confirm_password"
                  className={`form-control form-icon-input ${
                    touched.confirm_password && errors.confirm_password
                      ? 'is-invalid'
                      : ''
                  }`}
                  placeholder={`${t('enter')} ${t('confirm_password')}`}
                  value={values.confirm_password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  autoComplete="true"
                />
                {touched.confirm_password && errors.confirm_password && (
                  <Form.Control.Feedback type="invalid">
                    {errors.confirm_password}
                  </Form.Control.Feedback>
                )}
                <FontAwesomeIcon
                  icon={faKey}
                  className="text-body fs-9 form-icon"
                />
              </div>
            </Form.Group>
            <Button
              type="submit"
              variant="primary"
              className="w-100"
              disabled={loader.form}
            >
              {t('set_password')}
            </Button>
          </Form>
        )}
      </Formik>
      <Link to="/auth/sign-in" className="fs-9 fw-bold">
        {t('back_to_login')}
      </Link>
    </div>
  );
};

export default ResetPasswordForm;
