import { Container, Navbar } from 'react-bootstrap';
import NavbarBrand from '../components/navbars/nav-items/NavbarBrand';
import Button from '../components/base/Button';
import { PropsWithChildren } from 'react';
import Footer from '../components/footers/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationPin } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import ToastMessage from '../components/common/ToastMessage';

const PublicLayout = ({ children }: PropsWithChildren) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  return (
    <Container fluid className="px-0">
      <Navbar
        className="navbar-top fixed-top navbar-slim d-flex align-items-center justify-content-between"
        expand="lg"
        variant=""
        data-navbar-appearance="dark"
      >
        <NavbarBrand />
        <div className="d-flex align-items-center justify-content-end">
          <div className="me-4">
            <Button
              href="/track-my-case"
              variant="subtle-warning"
              className="w-100 rounded-pill"
            >
              <FontAwesomeIcon icon={faLocationPin} className="me-2" />
              {t('track_my_case')}
            </Button>
          </div>
        </div>
      </Navbar>

      <div className="content">
        {children}
        <Footer className="position-absolute" />
      </div>
      <ToastMessage />
    </Container>
  );
};

export default PublicLayout;
