import AuthSplitLayout from 'layouts/AuthSplitLayout';
import bg from 'assets/img/member.png';
import SignInForm from 'components/modules/auth/SignInForm';

// SignIn Component
// This component is responsible for rendering the Sign-In page.
// It uses a split layout design, displaying the sign-in form along with a background image.

const SignIn = () => {
  return (
    // AuthSplitLayout is used as the wrapper component to provide a split layout for the page.
    // It takes the background image (bg) as a prop to style the page accordingly.
    <AuthSplitLayout bg={bg}>
      {/* SignInForm is the main form for the user to sign in */}
      {/* It handles the logic for user authentication, such as input fields for email/username and password, */}
      {/* and submitting the credentials to the server for authentication */}
      <SignInForm />
    </AuthSplitLayout>
  );
};

export default SignIn;
