import React from 'react';
import * as Yup from "yup"
import support from 'assets/img/support.svg';
import support_svg from 'assets/img/mask.svg';
import complaint_svg from 'assets/img/complaint.svg'
import track2 from 'assets/img/track-2.png';
import {Col, Container, Form, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAt, faChevronRight, faPhone} from '@fortawesome/free-solid-svg-icons';
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import StatusBadge from '../../components/common/StatusBadge';
import PublicLayout from '../../layouts/PublicLayout';
import Button from 'components/base/Button';
import {Formik, FormikProps} from 'formik';
import {TIssue} from 'types/issues';
import {toast} from 'react-toastify';
import useIssueHook from 'hooks/modules/useIssueHook';
import IssueForm from 'components/modules/issue/forms/IssueForm';

// Initial state for selected issues
const initialComplaintValue: TIssue = {
    id: undefined,
    body: '',
    full_name: '',
    title: '',
    type: "",
    pre_case_id: ''
};

// TrackResults Component: A component for displaying tracking results and support information.
const TrackResults = () => {
        // Translation hook for multi-language support
        // eslint-disable-next-line
        const {t}: { t: any } = useTranslation();
        const [selected, setSelected] = React.useState<string>("")

        // useLocation hook is used to get the current location object, useIssueHook's method createIssue is being used to file a complaint
        const location = useLocation() || null
        const {createIssue} = useIssueHook()

        // Form validation schema using Yup
        const complaintValidationSchema = Yup.object({
            full_name: Yup.string().required(
                t('form_validation_mandatory', {
                        field: t('full_name')
                    }
                )),
            type: Yup.string().required(
                t('form_validation_mandatory', {
                        field: t('type')
                    }
                )),
            title: Yup.string().required(
                t('form_validation_mandatory', {
                        field: t('title')
                    }
                )),
            body: Yup.string().required(
                t('form_validation_mandatory', {
                        field: t('description')
                    }
                )),
        });

        // Submits form data to create or update an issue
        const handleComplaintSubmit = (formData: TIssue) => {
            if (!trackCase?.pre_intake_case_id) {
                toast.warning("Case ID is missing");
                return
            }

            if (trackCase?.pre_intake_case_id) {
                formData["pre_case_id"] = trackCase?.pre_intake_case_id
                createIssue(formData)
                    .then(() => {
                        setSelected('');
                        toast.success(`Complaint filed succesfully`);
                    })
                    .catch((e) => {
                        toast.error(e?.data?.message || 'Something Went Wrong');
                    });
            }
        };

        // Memoized variable that extracts the trackCase data from location state
        // Memoization ensures that the data is only recalculated when the location or trackCase changes
        const trackCase = React.useMemo(() => {
            return location?.state?.trackCase
        }, [location?.state?.trackCase])

        return (
            <React.Fragment>
                <PublicLayout>
                    <div className='d-flex align-items-center h-100'>
                        {/* Container for holding the page content */}
                        <Container>
                            <Row className='d-flex gap-4 gap-lg-0 justify-content-center'>
                                {/* Support Information Block: Displays contact details for assistance */}
                                <Col xs={12} md={12} lg={4}>
                                    <div
                                        className='d-flex align-items-start gap-3 bg-white p-5 rounded-3 support-block pb-7'
                                        style={{backgroundImage: `url(${support_svg})`}}>
                                        {/* Image representing support */}
                                        <img src={support} alt="support" width='48'/>

                                        <div className=''>
                                            {/* Heading for support section */}
                                            <h4 className='mb-3'>{t('support')}</h4>

                                            {/* Description for the support section */}
                                            <p className='mb-3'>{t('call_for_assistance')}</p>

                                            {/* Contact details for support */}
                                            <div className='d-flex flex-column gap-2'>
                                                {/* Phone number */}
                                                <div className='d-flex align-items-center'>
                                                    <FontAwesomeIcon icon={faPhone}
                                                                     className='me-2 text-primary fs-9'/><span
                                                    className='fw-bold'>+855 012 791 867</span>
                                                </div>

                                                {/* WhatsApp contact */}
                                                <div className='d-flex align-items-center'>
                                                    <FontAwesomeIcon icon={faWhatsapp}
                                                                     className='me-2 text-success fs-9'/><span
                                                    className='fw-bold'>+855 012 791 867</span>
                                                </div>

                                                {/* Email contact */}
                                                <div className='d-flex align-items-center'>
                                                    <FontAwesomeIcon icon={faAt} className='me-2 text-primary fs-9'/><span
                                                    className='fw-bold'>isscms@live.com</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className='d-flex align-items-start gap-2 bg-white p-5 rounded-3 support-block pb-7 mt-4 w-100'>
                                        {/* Image representing complaint */}
                                        <img src={complaint_svg} alt="support" width='48'/>

                                        <div className='d-flex flex-column align-items-start gap-1 w-100'>
                                            {/* Heading for complaint section */}
                                            <h4 className='mb-3'>{t('file_a_complaint')}</h4>

                                            {/* Description for the complaint section */}
                                            <p className='mb-3'>{t('need_to_file_a_complaint')}?</p>

                                            {/* Contact details for complaint */}
                                            <Button variant="warning"
                                                    className='d-flex align-items-center justify-content-center rounded-pill w-100 mb-4 mt-2 fs-8 fw-semibold'
                                                    onClick={() => setSelected("add")}>
                                                {t('file_a_complaint')}
                                            </Button>
                                        </div>
                                    </div>
                                </Col>

                                {/* Tracking Results Block: Displays case details and status */}
                                <Col xs={12} md={12} lg={6}>
                                    <div className="bg-white text-center rounded-3 d-flex flex-column gap-2 p-5">
                                        {/* Display an image related to tracking */}
                                        <img src={track2} alt="track" className="w-50 m-auto"/>
                                        {/* Tracking case ID */}
                                        <div className="fs-7 mt-3">
                                            {t('tracking_pre_case_id')}: <span
                                            className="fw-bold fs-7">{trackCase?.pre_intake_case_id}</span></div>
                                        {/*<p className='mb-1'>*/}
                                        {/*    {t('details_about_your_case')}*/}
                                        {/*</p>*/}

                                        {/* Conditional rendering of the current stage of the case */}
                                        {
                                            trackCase?.current_stage && (
                                                <>
                                                    {/* Displaying the current status of the case */}
                                                    <p className='mb-1'>{t('current_case_status')}:</p>
                                                    <h5>{trackCase?.current_stage}</h5>
                                                </>
                                            )
                                        }

                                        {/* Display the case status using StatusBadge component */}
                                        <div>
                                            <StatusBadge status={trackCase?.pre_intake_status as string}/>
                                        </div>
                                    </div>

                                    {/* Button to allow tracking another case */}
                                    <Link to="/track-my-case"
                                          className='btn btn-outline-warning rounded-pill w-100 mb-4 mt-4'>
                                        {t('track_another_case')}
                                        {/* Chevron icon indicating action */}
                                        <FontAwesomeIcon icon={faChevronRight} className="ps-2"/>
                                    </Link>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </PublicLayout>

                {/* Modal: File a complaint */}
                {selected === 'add' && (
                    <Formik
                        initialValues={initialComplaintValue}
                        validationSchema={complaintValidationSchema}
                        onSubmit={values => handleComplaintSubmit(values)}
                    >
                        {(formik: FormikProps<TIssue>) => (
                            < Form onSubmit={formik.handleSubmit}>
                                {/* Issue Form: Renders the form for filing a complaint */}
                                <IssueForm
                                    selected={selected}
                                    onClose={() => {
                                        setSelected('');
                                    }}
                                />
                            </Form>
                        )}
                    </Formik>
                )
                }
            </React.Fragment>
        )
            ;
    }
;

export default TrackResults;
