import { Card, Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarDay,
  faClock,
  faClockRotateLeft
} from '@fortawesome/free-solid-svg-icons';
import emptyIcon from '../../../../assets/img/empty-icon.png';
import { TPreIntakeHistory } from '../../../../types/intake';
import PhoenixLoader from '../../../../components/common/PhoenixLoader';
import {
  convertTimeDate,
  convertTimeToHumanTime
} from '../../../../helpers/date';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import useFormOptionHook from '../../../../hooks/modules/mdm/useFormOption';
import useCookies from '../../../../hooks/useCookiesHook';

//interfaces
interface PreIntakeHistoryProps {
  preIntakeHistories: TPreIntakeHistory[];
  loading: boolean;
}

/**
 * Displays the history of pre-intake data.
 * It handles both the loading state and renders a list of pre-intake history entries.
 * @returns {JSX.Element} - The JSX element that represents the component.
 */
const PreIntakeHistory = ({
  loading,
  preIntakeHistories
}: PreIntakeHistoryProps) => {
  // Translation hook for internationalization (i18n).
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  const { formOptions } = useFormOptionHook();
  const { isEnglishLanguage } = useCookies();
  const preIntakeHistoryOptions = useMemo(() => {
    return formOptions
      .filter(data => data?.slug == 'pre-intake-history')
      .map(data => {
        return {
          ...data,
          ...(!isEnglishLanguage && data.label_alt && { label: data.label_alt })
        };
      });
  }, [formOptions, isEnglishLanguage]);

  return (
    <>
      <Card className="border-0 shadow rounded-3 mb-5">
        <Card.Body>
          {/* If not loading, display the content */}
          {!loading ? (
            <>
              <div className="d-flex  align-items-center justify-content-between mb-3">
                <h3>{t('history')}</h3>

                {/* Icon inside a rounded circle that represents an action */}
                <div
                  className="d-flex bg-primary-subtle rounded-circle bg-danger flex-center"
                  style={{ width: '32px', height: '32px' }}
                >
                  <FontAwesomeIcon
                    icon={faClockRotateLeft}
                    className="fs-9 text-primary"
                  />
                </div>
              </div>

              {/* Check if there are pre-intake history entries */}
              {preIntakeHistories.length > 0 ? (
                // If there are entries, map over the array and render each history entry
                <Stack>
                  {preIntakeHistories.map((data, i) => (
                    <div key={`preIntakeHistories-${i}`}>
                      {/* Content for each pre-intake history entry */}
                      <div className="d-flex justify-content-between">
                        <div className="d-flex gap-2 flex-column">
                          <div>
                            {/* Display the title of the history entry */}
                            <h5 className="mb-1">
                              {
                                preIntakeHistoryOptions.find(
                                  item => item.value == (data?.title as string)
                                )?.label
                              }
                            </h5>

                            {/* Display the description of the history entry */}
                            <span>{data?.description}</span>
                          </div>

                          {/* Stack for displaying date and time information */}
                          <Stack direction="horizontal" gap={3}>
                            <div>
                              {/* Icon for the creation date */}
                              <FontAwesomeIcon
                                icon={faCalendarDay}
                                className="me-2 text-body-quaternary fs-9"
                              />

                              {/* Display the formatted creation date */}
                              <span>
                                {' '}
                                {data?.created_at
                                  ? convertTimeDate(data.created_at)
                                  : 'N/A'}{' '}
                              </span>
                            </div>
                            <div>
                              {/* Icon for the creation time */}
                              <FontAwesomeIcon
                                icon={faClock}
                                className="me-2 text-body-quaternary fs-9"
                              />

                              {/* Display the formatted creation time */}
                              <span>
                                {' '}
                                {data?.created_at
                                  ? convertTimeToHumanTime(data.created_at)
                                  : 'N/A'}{' '}
                              </span>
                            </div>
                          </Stack>
                        </div>
                      </div>

                      {/* Divider between history entries */}
                      <hr />
                    </div>
                  ))}
                </Stack>
              ) : (
                // If no history entries are available, show a placeholder message
                <Stack gap={3} className="text-center">
                  <div>
                    {/* Display an empty icon image */}
                    <img width="130" src={emptyIcon} />
                  </div>

                  {/* Display a message indicating no entries are available */}
                  <p>{t('no_entries_yet_to_show')}</p>
                </Stack>
              )}
            </>
          ) : (
            // If the data is loading, show a loading spinner
            <PhoenixLoader />
          )}
        </Card.Body>
      </Card>
    </>
  );
};
export default PreIntakeHistory;
