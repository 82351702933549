import { createSlice } from '@reduxjs/toolkit';
import { TTranslation } from '../../types/translation';

interface TranslationState {
  translations: TTranslation[];
  translation: TTranslation;
}

const initialState: TranslationState = {
  translations: [],
  translation: <TTranslation>{}
};

const translationSlice = createSlice({
  name: 'translation',
  initialState,
  reducers: {
    setAllTranslation(state, action) {
      state.translations = action.payload.results || [];
    },
    addOrSetOneTranslation(state, action) {
      const tempTranslation = state.translations.find(
        data => data.key == action.payload.row?.key
      );
      if (tempTranslation) {
        state.translations = state.translations.map(item => {
          if (item.key !== action.payload.row?.key) return item;
          else return { ...item, ...action.payload.row };
        });
      } else {
        state.translations = [...state.translations, action.payload.row];
      }
    },
    removeOneTranslation(state, action) {
      state.translations = state.translations.filter(
        item => item.key !== action.payload.key
      );
    }
  }
});

export const {
  setAllTranslation,
  addOrSetOneTranslation,
  removeOneTranslation
} = translationSlice.actions;
export default translationSlice.reducer;
