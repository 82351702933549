import api from '../utils/api';
import { serializedObject } from 'helpers/utils';
import { TFilterIssue, TIssue } from 'types/issues';

const authEndpoint: string = `${process.env.REACT_APP_ENDPOINT}/case/pre-intake/complaint`;

const fetchAll = (filters: TFilterIssue) => {
  return api.get(`${authEndpoint}?${serializedObject(filters)}`);
};

const fetchOne = (id: string) => {
  return api.get(`${authEndpoint}/${id}`);
};

const create = (data: TIssue) => {
  return api.post(`${authEndpoint}`, data);
};

export default {
  fetchAll,
  fetchOne,
  create
};
